import { LitElement, html, css } from 'lit';
import alertActiveIcon from '@getgo/chameleon-icons/dist/rebranded/alert-filled.svg';
import encryptionActiveIcon from '@getgo/chameleon-icons/dist/rebranded/encryption-filled.svg';
import downloadIcon from '@getgo/chameleon-icons/dist/rebranded/download-filled.svg';
import loaderIcon from '@getgo/chameleon-icons/dist/rebranded/loader.svg';
import copyOutlinedIcon from '@getgo/chameleon-icons/dist/rebranded/copy-outlined.svg';
import helpSquareInactiveIcon from '@getgo/chameleon-icons/dist/rebranded/help-square-outlined.svg';
import i18n, { TFunction } from 'services/app/i18n';
import { HealthState, THealthState } from 'app_constants/status';
import { getHealthState } from 'utils/device';

const COMPONENT_TAG_NAME = 'av-health';

class AVHealth extends LitElement {
  t: TFunction;
  status: THealthState;

  static get properties() {
    return {
      status: { type: String },
    };
  }

  static get styles() {
    return [
      css`
        .wrapper {
          display: flex;
          align-items: center;
        }

        .icon {
          --goto-icon-size: 20px;
          margin-right: var(--goto-spacing-01);
        }

        @keyframes spinning-icon {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }

        .icon--spinning {
          animation: spinning-icon 1s linear infinite;
        }

        .text {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      `,
    ];
  }

  constructor() {
    super();
    this.t = i18n.getFixedT(null, 'DevicesTable');
    this.status = HealthState.UNKNOWN;
  }

  statusTemplate({ icon, color, spinning }: { icon: string; color?: string; spinning?: boolean }) {
    const text = getHealthState(this.status);

    return html`
      <div class="wrapper">
        <synapse-icon
          class="icon ${spinning && 'icon--spinning'}"
          style="${color && `--goto-icon-color: var(--goto-icon-color-${color})`}"
          .svg="${icon}"
        ></synapse-icon>
        <div class="text" title="${text.join(', ')}">
          ${text.map((phrase) => html`${phrase}<br />`)}
        </div>
      </div>
    `;
  }

  render() {
    switch (this.status) {
      case HealthState.DEFINITION_OUTDATED: {
        return this.statusTemplate({
          icon: downloadIcon,
          color: 'warning',
        });
      }

      case HealthState.THREAT_FOUND: {
        return this.statusTemplate({
          icon: alertActiveIcon,
          color: 'danger',
        });
      }

      case HealthState.PROTECTED: {
        return this.statusTemplate({
          icon: encryptionActiveIcon,
          color: 'success',
        });
      }

      case HealthState.SCAN_IN_PROGRESS: {
        return this.statusTemplate({
          icon: loaderIcon,
          color: 'brand-default',
          spinning: true,
        });
      }

      case HealthState.MULTIPLE_AVS_INSTALLED: {
        return this.statusTemplate({
          icon: copyOutlinedIcon,
          color: 'warning',
        });
      }

      case HealthState.GTRE_EPP_INSTALLATION_IN_PROGRESS: {
        return this.statusTemplate({
          icon: loaderIcon,
          color: 'brand-default',
          spinning: true,
        });
      }

      case HealthState.UNKNOWN:
      default: {
        return this.statusTemplate({
          icon: helpSquareInactiveIcon,
          color: 'default',
        });
      }
    }
  }
}

if (!customElements.get(COMPONENT_TAG_NAME)) {
  customElements.define(COMPONENT_TAG_NAME, AVHealth);
}

export default AVHealth;
