export const avFieldsFragment = /* GraphQL */ `
  fragment AVFields on Device {
    av {
      rtpAvailable
      rtpState
      detectedAVSignature
      detectedAVName
      lastScanOn
      updatedOn
      definitionNumber
      avHealthState
      avInstalled
      lastReportOn
      scanState
      scanAvailable
      updateDefinitionsAvailable
    }
  }
`;

export const firewallFieldsFragment = /* GraphQL */ `
  fragment FirewallFields on Device {
    firewall {
      firewallState
      firewallAvailable
      detectedFirewallName
      detectedFirewallSignature
    }
  }
`;

export const issuesFieldsFragment = /* GraphQL */ `
  fragment IssuesFields on Device {
    issues {
      deviceDuplicationError
      macAccessibilityPermissionMissing
      macScreenRecordingPermissionMissing
      zeroTrustError
    }
  }
`;

export const pmmFieldsFragment = /* GraphQL */ `
  fragment PMMFields on Device {
    patchmanagement {
      appInstallationCapability
      appUpdateCapability
    }
  }
`;

const groupNameFieldFragment = /* GraphQL */ `
  fragment GroupNameFieldFragment on Device {
    group {
      name
    }
  }
`;

export const defaultFieldsFragment = /* GraphQL */ `
  fragment DefaultDeviceFields on Device {
    id
    status
    lastAccess
    lastLoggedInUser {
      userName
    }
    firstSeen
    lastSeen
    premium
    status
    zeroTrustEnabled
    deviceName
    labels
    hostName
    osName
    osVersion
    osActivation
    clientVersion
    clientType
    connectivity {
      lastChanged
      status
    }
    ipAddresses
    macAddresses
    biosVersion
    biosSerialNumber
    shellUrl
    companyId
    inSession
    locked
    platform
    processor
    systemFolder
    systemManufacturer
    systemModel
    timeZone
    timeZoneOffset
    tlsInfo
    totalPhysicalMemory
    userLocale
    windowsFolder
    verified
    deviceGroupId
    foreignSystemId
    foreignSystemKind
    notes
  }
`;
export const getFieldsQuery = /** @type {(withGroupName?: boolean) => string} */ (
  withGroupName
) => /* GraphQL */ `
  ${defaultFieldsFragment}
  ${avFieldsFragment}
  ${firewallFieldsFragment}
  ${issuesFieldsFragment}
  ${pmmFieldsFragment}
  ${withGroupName ? groupNameFieldFragment : ''}

  query GetDevicesQuery(
    $offset: Int
    $first: Int
    $sort: Sort
    $verified: Boolean
    $deviceGroupIds: [String!]
    $premium: Boolean
    $platforms: [Platform!]
    $statuses: [StatusFilter!]
    $labels: [String!]
    $lastAccess: DateFilter
    $lastSeen: DateFilter
    $av: AntiVirusFilters
    $firewall: FirewallFilters
    $patchmanagement: PatchManagementFilters
    $searchText: String
  ) {
    getDevices(
      input: {
        pagination: { offset: $offset, first: $first, sort: $sort }
        advancedFilters: {
          verified: $verified
          deviceGroupIds: $deviceGroupIds
          premium: $premium
          platforms: $platforms
          statuses: $statuses
          labels: $labels
          lastAccess: $lastAccess
          lastSeen: $lastSeen
          av: $av
          firewall: $firewall
          patchmanagement: $patchmanagement
          searchText: $searchText
        }
      }
    ) {
      ...DefaultDeviceFields
      ...AVFields
      ...FirewallFields
      ...IssuesFields
      ...PMMFields
      ${withGroupName ? `...GroupNameFieldFragment` : ''}
    }
    getAggregateBy(
      input: {
        advancedFilters: {
          verified: $verified
          deviceGroupIds: $deviceGroupIds
          premium: $premium
          platforms: $platforms
          statuses: $statuses
          labels: $labels
          lastAccess: $lastAccess
          av: $av
          firewall: $firewall
          patchmanagement: $patchmanagement
          searchText: $searchText
        }
      }
    ) {
      aggregate {
        totalDeviceCount
      }
    }
  }
`;
