import i18n from 'services/app/i18n';

const t = i18n.getFixedT(null, 'DevicesTable');

export const HealthState = {
  UNKNOWN: 'UNKNOWN',
  PROTECTED: 'PROTECTED',
  THREAT_FOUND: 'THREATFOUND',
  DEFINITION_OUTDATED: 'DEFINITIONOUTDATED',
  SCAN_IN_PROGRESS: 'SCANINPROGRESS',
  MULTIPLE_AVS_INSTALLED: 'MULTIPLEAVSINSTALLED',
  GTRE_EPP_INSTALLATION_IN_PROGRESS: 'GTRE_EPP_INSTALLATION_IN_PROGRESS',
  UNSUPPORTED_DEVICE: 'UNSUPPORTED_DEVICE',
} as const;

export type THealthState = (typeof HealthState)[keyof typeof HealthState];

export const ScanState = {
  UNKNOWN: 'UNKNOWN',
  IN_PROGRESS: 'INPROGRESS',
  READY_TO_SCAN: 'READYTOSCAN',
} as const;

export type TScanState = (typeof ScanState)[keyof typeof ScanState];

export const RtpState = {
  UNKNOWN: 'UNKNOWN',
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED',
  UNSUPPORTED_DEVICE: 'UNSUPPORTED_DEVICE',
} as const;

export const rtpMap = {
  [RtpState.UNKNOWN]: {
    table: t('unknown'),
    class: 'unknown',
    menu: t('unknown'),
    report: t('unknown'),
  },
  [RtpState.UNSUPPORTED_DEVICE]: {
    table: t('unsupported-device'),
    class: 'unsupported-device',
    menu: t('unknown'),
    report: t('unknown'),
  },
  [RtpState.ENABLED]: {
    table: t('enabled'),
    class: 'enabled',
    menu: t('Common:disable') + ' ' + t('option-rtp'),
    report: t('real-time-protection') + ' ' + t('enabled').toLowerCase(),
  },
  [RtpState.DISABLED]: {
    table: t('disabled'),
    class: 'disabled',
    menu: t('Common:enable') + ' ' + t('option-rtp'),
    report: t('real-time-protection') + ' ' + t('disabled').toLowerCase(),
  },
};

export type TRtpState = (typeof RtpState)[keyof typeof RtpState];

export const FirewallState = {
  UNSUPPORTEDDEVICE: 'UNSUPPORTEDDEVICE',
  NOTSUPPORTED: 'NOTSUPPORTED',
  UNKNOWN: 'UNKNOWN',
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED',
} as const;

export const firewallMap = {
  [FirewallState.UNSUPPORTEDDEVICE]: {
    table: t('unsupported-device'),
    class: 'unsupported-device',
    menu: t('unknown'),
    report: t('unknown'),
  },
  [FirewallState.NOTSUPPORTED]: {
    table: t('notsupported'),
    class: 'notsupported',
    menu: t('unknown'),
    report: t('unknown'),
  },
  [FirewallState.UNKNOWN]: {
    table: t('unknown'),
    class: 'unknown',
    menu: t('unknown'),
    report: t('unknown'),
  },
  [FirewallState.ENABLED]: {
    table: t('enabled'),
    class: 'enabled',
    menu: t('Common:disable') + ' ' + t('option-firewall'),
    report: t('firewall') + ' ' + t('enabled').toLowerCase(),
  },
  [FirewallState.DISABLED]: {
    table: t('disabled'),
    class: 'disabled',
    menu: t('Common:enable') + ' ' + t('option-firewall'),
    report: t('firewall') + ' ' + t('disabled').toLowerCase(),
  },
};

export type TFirewallState = (typeof FirewallState)[keyof typeof FirewallState];

export const DeviceStatus = {
  ONLINE: 'Online',
  IN_SESSION: 'In Session',
  OFFLINE: 'Offline',
} as const;

export type TDeviceStatus = (typeof DeviceStatus)[keyof typeof DeviceStatus];

export const HelpdeskPriorityStatus = {
  URGENT: 1,
  HIGH: 2,
  MEDIUM: 3,
  LOW: 4,
} as const;

export type THelpdeskPriorityStatus =
  (typeof HelpdeskPriorityStatus)[keyof typeof HelpdeskPriorityStatus];

export const AppInstallationState = {
  AVAILABLE: 'AVAILABLE',
  UNKNOWN_ERROR: 'UNKNOWNERROR',
  WINGET_NOT_FOUND: 'WINGETNOTFOUND',
  WINGET_NOT_ACCESSIBLE: 'WINGETNOTACCESSIBLE',
} as const;

export type TAppInstallationState =
  (typeof AppInstallationState)[keyof typeof AppInstallationState];
