import React, {
  useCallback,
  useLayoutEffect,
  useState,
  useContext,
  useDeferredValue,
  useEffect,
  useRef,
} from 'react';
import i18n from 'services/app/i18n';
import { useTranslation } from 'react-i18next';
import { Chip, SearchField } from '@getgo/chameleon-web-react-wrapper';
import { useLocation } from 'react-router-dom';
import { navigate } from 'synapse-js/events/navigation';
import { useQuery } from '@tanstack/react-query';
import { AppSettingContext } from 'shared/contexts/app_setting';
import { useModal, useZTSignature } from 'shared/hooks';
import { getSynapseToken } from 'services/auth';
import telemetry from 'services/app/telemetry';
import {
  MODAL_ACTIONS,
  CREATE_TICKET_MODAL,
  ENABLE_RTP_MODAL,
  ENABLE_FIREWALL_MODAL,
  DISABLE_FIREWALL_MODAL,
  FULL_SCAN_MODAL,
  LAST_SCAN_REPORT_MODAL,
  UPDATE_DEFINITIONS_MODAL,
  QUICK_SCAN_MODAL,
  INSTALLATION_STALLED,
} from 'app_constants/modal';
import { ExternalPath } from 'app_constants/path';
import { Integration, Platform } from 'app_constants';
import { TAppOptions, TDevice } from 'types';
import { TDeviceFilter } from './types';
import useSelectedDevices, { selectedDevicesSubject$ } from 'observables/selectedDevices';
import useSelectedGroups, { selectedGroupsSubject$ } from 'observables/selectedGroups';
import useSelectionSummaryData, {
  selectionSummaryDataSubject$,
} from 'observables/selectionSummaryData';
import useDrawer, { drawerOpened } from 'observables/drawer';
import { commandApi, selectionSummaryApi } from 'services/api';
import './DevicesTable';
import type DevicesTable from './DevicesTable';
import './Drawer';
import './AntivirusTable.scss';
import { useLicenses } from 'shared/hooks/useLicenses/useLicenses';
import { logError, logErrorWithDescription } from 'services/app/telemetry/telemetry';
import { DevicesFilterRecord } from 'shared/hooks/useSettings/useSettings';
import { DevicesListEmptyState } from './DevicesTable/EmptyState';
import { useDevicePolicyAssignments } from '../../shared/hooks/usePolicyAssignments/useDevicePolicyAssignments';

const AntivirusTable = () => {
  const listRef: React.RefObject<HTMLElement & DevicesTable> = React.createRef();
  const drawerRef: React.RefObject<HTMLElement> = React.createRef();

  const { t } = useTranslation('AntivirusTable');

  const { state: selectedDevices, add: addDevice } = useSelectedDevices();
  const { state: selectedGroups, add: addGroups } = useSelectedGroups();

  const { state: selectionSummaryData, add: addSelectionSummaryData } = useSelectionSummaryData();

  const [filters, setFilters] = useState<TDeviceFilter[]>([
    {
      idx: 0,
      name: t('all-devices'),
      selected: true,
      data: {},
      trackingProp: 'all_devices_list',
    },
    {
      idx: 1,
      name: t('gtre-epp-devices'),
      selected: false,
      data: { av: { isGTReEpp: true } },
      trackingProp: 'GTRe_EPP_devices_list',
    },
    {
      idx: 2,
      name: t('pre-installed-av-devices'),
      selected: false,
      data: { av: { isGTReEpp: false, avInstalled: true } },
      trackingProp: 'pre_installed_AV_devices_list',
    },
  ]);
  const deferredFilters = useDeferredValue(filters);

  const {
    state: devicesFilterRecord,
  }: { state: { devicesFilter?: DevicesFilterRecord } | undefined } = useLocation();

  const setInitialDevicesFilter = useRef(false);

  const selectedFilterId = devicesFilterRecord?.devicesFilter?.idx;
  if (!setInitialDevicesFilter.current && selectedFilterId) {
    setInitialDevicesFilter.current = true;
    const updatedFilters = getUpdatedFilters(filters, selectedFilterId);
    setFilters(updatedFilters);
  }

  const { isFetching: isSelectionSummaryLoading, refetch } = useQuery(
    ['selection-summary-data'],
    () => {
      const selectedFilter = deferredFilters.find((filter) => filter.selected);

      return selectionSummaryApi.getSelectionSummary({
        devices: selectedDevices,
        groupIds: selectedGroups,
        isGtreEpp: selectedFilter?.data.av?.isGTReEpp,
      });
    },
    {
      enabled: false,
      onError: logErrorWithDescription('Error: fetching selection summary'),
      onSuccess: (data) => {
        addSelectionSummaryData(data);
      },
    }
  );

  const {
    state: { isOpen: isDrawerOpen },
    setClosed: closeDrawer,
  } = useDrawer();

  const { openZTSignatureModal } = useZTSignature();

  const { companyLicenses } = useLicenses();

  const { open } = useModal();

  const checkLicenses = React.useRef((): boolean => {
    open('LicenseWarning');

    return false;
  });
  useEffect(() => {
    checkLicenses.current = (): boolean => {
      if (
        (companyLicenses?.avLicensesProvisioned || 0) > (companyLicenses?.avLicensesInstalled || 0)
      ) {
        return true;
      }
      open('LicenseWarning');

      return false;
    };
  }, [companyLicenses?.avLicensesProvisioned, companyLicenses?.avLicensesInstalled, open]);

  const deviceCompanyAssignments = useDevicePolicyAssignments('bitdefender');

  const [searchText, setSearchText] = useState('');
  const deferredSearchText = useDeferredValue(searchText);

  const { theme, language, options } = useContext(AppSettingContext);

  const [showPolicyColumn, setShowPolicyColumn] = useState(false);
  useEffect(() => {
    const parsedOptions: TAppOptions = options.startsWith('{') && JSON.parse(options);
    const policiesFeatureFlag = parsedOptions?.features?.Policies ?? false;
    const hasLicenses = (companyLicenses?.avLicensesProvisioned ?? 0) > 0;
    setShowPolicyColumn(hasLicenses && policiesFeatureFlag);
  }, [companyLicenses?.avLicensesProvisioned, options]);

  const token = getSynapseToken();

  const onDeviceSelected = useCallback(
    (devices: TDevice[]) => {
      addDevice(devices.filter((device) => device));
    },
    [addDevice]
  );

  const onGroupSelected = useCallback(
    (groups: (string | null)[]) => {
      addGroups(groups);
    },
    [addGroups]
  );

  const [totalDeviceCount, setTotalDeviceCount] = useState<number | undefined>(undefined);
  const searchTextRef = useRef(searchText);

  useEffect(() => {
    type DevicesLoaded = { totalDeviceCount: number };
    const devicesLoadedHandler = function (e: unknown) {
      const currentSearchText = searchTextRef.current;
      const event = e as CustomEvent<DevicesLoaded>;
      if (currentSearchText === '') {
        setTotalDeviceCount(event.detail.totalDeviceCount);
      }
    };
    document.addEventListener('devices-loaded', devicesLoadedHandler);

    return () => {
      document.removeEventListener('devices-loaded', () => undefined);
    };
  }, []);

  useEffect(() => {
    const subscription = drawerOpened.subscribe(() => {
      refetch();
    });

    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelectedQuickScanClicked = useCallback(() => {
    openZTSignatureModal({
      devices: selectedDevicesSubject$.value,
      groupIds: selectedGroupsSubject$.value,
      command: commandApi.quickScan,
      action: MODAL_ACTIONS[QUICK_SCAN_MODAL],
      modalText: i18n.getFixedT(null, QUICK_SCAN_MODAL)('caption', {
        count: selectionSummaryDataSubject$.value?.devicesSelected,
      })
    });
  }, [openZTSignatureModal]);

  const onSelectedFullScanClicked = useCallback(() => {
    openZTSignatureModal({
      devices: selectedDevicesSubject$.value,
      groupIds: selectedGroupsSubject$.value,
      command: commandApi.fullScan,
      action: MODAL_ACTIONS[FULL_SCAN_MODAL],
      modalText: i18n.getFixedT(null, FULL_SCAN_MODAL)('caption', {
        count: selectionSummaryDataSubject$.value?.devicesSelected,
      }),
    });
  }, [openZTSignatureModal]);

  const onSelectedUpdateDefinitionsClicked = useCallback(() => {
    openZTSignatureModal({
      devices: selectedDevicesSubject$.value,
      groupIds: selectedGroupsSubject$.value,
      command: commandApi.updateDefinitions,
      action: MODAL_ACTIONS[UPDATE_DEFINITIONS_MODAL],
      modalText: i18n.getFixedT(null, UPDATE_DEFINITIONS_MODAL)('caption', {
        count: selectionSummaryDataSubject$.value?.devicesSelected,
      }),
    });
  }, [openZTSignatureModal]);

  const onSelectedEnableRTPClicked = useCallback(() => {
    openZTSignatureModal({
      devices: selectedDevicesSubject$.value,
      groupIds: selectedGroupsSubject$.value,
      command: commandApi.enableRTP,
      action: MODAL_ACTIONS[ENABLE_RTP_MODAL],
      modalText: i18n.getFixedT(null, ENABLE_RTP_MODAL)('caption', {
        count: selectionSummaryDataSubject$.value?.devicesSelected,
      }),
    });
  }, [openZTSignatureModal]);

  const onSelectedEnableFirewallClicked = useCallback(() => {
    openZTSignatureModal({
      devices: selectedDevicesSubject$.value,
      groupIds: selectedGroupsSubject$.value,
      command: commandApi.enableFirewall,
      action: MODAL_ACTIONS[ENABLE_FIREWALL_MODAL],
      modalText: i18n.getFixedT(null, ENABLE_FIREWALL_MODAL)('caption', {
        count: selectionSummaryDataSubject$.value?.devicesSelected,
      }),
    });
  }, [openZTSignatureModal]);

  const onSelectedDisableFirewallClicked = useCallback(() => {
    openZTSignatureModal({
      devices: selectedDevicesSubject$.value,
      groupIds: selectedGroupsSubject$.value,
      command: commandApi.disableFirewall,
      action: MODAL_ACTIONS[DISABLE_FIREWALL_MODAL],
      modalText: i18n.getFixedT(null, DISABLE_FIREWALL_MODAL)('caption', {
        count: selectionSummaryDataSubject$.value?.devicesSelected,
      }),
    });
  }, [openZTSignatureModal]);

  const clearSelection = useCallback(() => {
    window.dispatchEvent(
      new CustomEvent('clear-selection', {
        bubbles: true,
        composed: true,
      })
    );
  }, []);

  const closeDrawerAndClearSelection = useCallback(() => {
    closeDrawer();
    clearSelection();
  }, []);

  const onDeviceClicked = useCallback((device: TDevice) => {
    // eslint-disable-next-line no-console
    console.log('Device clicked', device);
  }, []);

  const onQuickScanClicked = useCallback(
    (device: TDevice) => {
      openZTSignatureModal({
        devices: [device],
        command: commandApi.quickScan,
        action: MODAL_ACTIONS[QUICK_SCAN_MODAL],
        modalText: i18n.getFixedT(null, QUICK_SCAN_MODAL)('caption', { count: 1 }),
      });
    },
    [openZTSignatureModal]
  );

  const onFullScanClicked = useCallback(
    (device: TDevice) => {
      openZTSignatureModal({
        devices: [device],
        command: commandApi.fullScan,
        action: MODAL_ACTIONS[FULL_SCAN_MODAL],
        modalText: i18n.getFixedT(null, FULL_SCAN_MODAL)('caption', { count: 1 }),
      });
    },
    [openZTSignatureModal]
  );

  const onCreateTicketClicked = useCallback(
    (device: TDevice) => {
      open(CREATE_TICKET_MODAL, device);
    },
    [open]
  );

  const contextMenuHandlers = {
    runFullScan: (device: TDevice) => {
      openZTSignatureModal({
        devices: [device],
        command: commandApi.fullScan,
        action: MODAL_ACTIONS[FULL_SCAN_MODAL],
        modalText: i18n.getFixedT(null, FULL_SCAN_MODAL)('caption', { count: 1 }),
      });
    },
    updateVirusDefinition: (device: TDevice) => {
      openZTSignatureModal({
        devices: [device],
        command: commandApi.updateDefinitions,
        action: MODAL_ACTIONS[UPDATE_DEFINITIONS_MODAL],
        modalText: i18n.getFixedT(null, UPDATE_DEFINITIONS_MODAL)('caption', { count: 1 }),
      });
    },
    showLastScanReport: (device: TDevice) => {
      open(LAST_SCAN_REPORT_MODAL, device);
    },
    enableRTP: (device: TDevice) => {
      openZTSignatureModal({
        devices: [device],
        command: commandApi.enableRTP,
        action: MODAL_ACTIONS[ENABLE_RTP_MODAL],
        modalText: i18n.getFixedT(null, ENABLE_RTP_MODAL)('caption', { count: 1 }),
      });
    },
    enableFirewall: (device: TDevice) => {
      openZTSignatureModal({
        devices: [device],
        command: commandApi.enableFirewall,
        action: MODAL_ACTIONS[ENABLE_FIREWALL_MODAL],
        modalText: i18n.getFixedT(null, ENABLE_FIREWALL_MODAL)('caption', { count: 1 }),
      });
    },
    disableFirewall: (device: TDevice) => {
      openZTSignatureModal({
        devices: [device],
        command: commandApi.disableFirewall,
        action: MODAL_ACTIONS[DISABLE_FIREWALL_MODAL],
        modalText: i18n.getFixedT(null, DISABLE_FIREWALL_MODAL)('caption', { count: 1 }),
      });
    },
    setupAlerts: (device: TDevice) => {
      navigate(
        `/policy/new?devicesIds=${device.id}${device.deviceGroupId ? `&groupIds=${device.deviceGroupId}` : ''
        }&platform=${Platform.WINDOWS}`,
        ExternalPath.ALERTS
      );
    },
  };

  const resetDevicesTable = () => setTotalDeviceCount(undefined);

  const onFilterClicked = (clickedFilterIndex: number) => {
    if (filters.find((filter) => filter.selected)?.idx === clickedFilterIndex) {
      return;
    }
    resetDevicesTable();

    const updatedFilters = getUpdatedFilters(filters, clickedFilterIndex);

    const { trackingProp, selected } = updatedFilters[clickedFilterIndex];
    if (selected) {
      telemetry.trackEvent(telemetry.events.FILTER_AV_LIST, {
        Filter_applied: trackingProp,
        integration_used: Integration.OPSWAT,
      });
    }

    const devicesTableElement = listRef.current;

    if (devicesTableElement) {
      devicesTableElement.filters = updatedFilters;
    }

    clearSelection();

    setFilters(updatedFilters);
  };

  useLayoutEffect(() => {
    const devicesTableElement = listRef.current;

    if (devicesTableElement) {
      devicesTableElement.onDeviceSelected = onDeviceSelected;
      devicesTableElement.onGroupSelected = onGroupSelected;
      devicesTableElement.onDeviceClicked = onDeviceClicked;
      devicesTableElement.onQuickScanClicked = onQuickScanClicked;
      devicesTableElement.onFullScanClicked = onFullScanClicked;
      devicesTableElement.onCreateTicketClicked = onCreateTicketClicked;
      devicesTableElement.contextMenuHandlers = contextMenuHandlers;
      devicesTableElement.filters = filters;

      if (!devicesTableElement.checkLicenses) {
        devicesTableElement.checkLicenses = () => checkLicenses.current();
      }
      if (!devicesTableElement.devicePolicyMap) {
        devicesTableElement.devicePolicyMap = deviceCompanyAssignments;
      }
      devicesTableElement.showPolicyColumn = showPolicyColumn;
    }

    const drawerElement = drawerRef.current;

    if (drawerElement) {
      drawerElement.onSelectedQuickScanClicked = onSelectedQuickScanClicked;
      drawerElement.onSelectedFullScanClicked = onSelectedFullScanClicked;
      drawerElement.onSelectedUpdateDefinitionsClicked = onSelectedUpdateDefinitionsClicked;
      drawerElement.onSelectedEnableRTPClicked = onSelectedEnableRTPClicked;
      drawerElement.onSelectedEnableFirewallClicked = onSelectedEnableFirewallClicked;
      drawerElement.onSelectedDisableFirewallClicked = onSelectedDisableFirewallClicked;
      drawerElement.onClearSelectionClicked = clearSelection;
      drawerElement.closeDrawer = closeDrawerAndClearSelection;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalDeviceCount, deviceCompanyAssignments, showPolicyColumn]);

  useEffect(() => {
    const stalledHandler = () => {
      open(INSTALLATION_STALLED);
    };
    document.body.addEventListener('installation-stalled', stalledHandler);

    return () => document.body.removeEventListener('installation-stalled', stalledHandler);
  }, [open]);

  useEffect(() => {
    const noLicenseLeftHandler = () => {
      open('LicenseWarning');
    };
    document.body.addEventListener('installation-failed-no-license-left', noLicenseLeftHandler);

    return () =>
      document.body.removeEventListener(
        'installation-failed-no-license-left',
        noLicenseLeftHandler
      );
  });

  useEffect(() => {
    const unknownInstallErrorHandler = () => {
      logError(new Error('AV Installation failed for unknown reasons'));
    };
    document.body.addEventListener('installation-failed-unknown-error', unknownInstallErrorHandler);

    return () =>
      document.body.removeEventListener(
        'installation-failed-unknown-error',
        unknownInstallErrorHandler
      );
  });

  const isEmptyState = () => totalDeviceCount !== undefined && totalDeviceCount === 0;

  return (
    <div className="antivirus-table">
      <div className="antivirus-table-controls">
        <div className="antivirus-table-filters">
          {filters.map((filter, index) => (
            <Chip
              key={index}
              className="antivirus-table-filters__item"
              selected={filter.selected}
              onClick={() => onFilterClicked(index)}
            >
              {filter.name}
            </Chip>
          ))}
        </div>
        <SearchField
          className="antivirus-table-search-field"
          placeholder={t('search-placeholder')}
          value={searchText}
          onChange={({ target: { value } }) => {
            if (!isEmptyState()) {
              searchTextRef.current = value || '';
              setSearchText(value || '');
            }
          }}
        ></SearchField>
      </div>
      {!isEmptyState() ? (
        <>
          <av-devices-table
            theme={theme}
            language={language}
            token={token}
            options={options}
            ref={listRef}
            search-text={deferredSearchText}
            filter-name={filters.find((filter: TDeviceFilter) => filter.selected)?.name || ''}
          ></av-devices-table>
          <av-devices-table-drawer
            ref={drawerRef}
            is-drawer-open={JSON.stringify(isDrawerOpen)}
            is-selection-summary-loading={JSON.stringify(isSelectionSummaryLoading)}
            selection-summary-data={JSON.stringify(selectionSummaryData)}
          ></av-devices-table-drawer>
        </>
      ) : (
        <div className="antivirus-table-empty-state-container">
          <DevicesListEmptyState
            filters={filters}
            setFilters={setFilters}
            resetDevicesTable={resetDevicesTable}
          />
        </div>
      )}
    </div>
  );
};

export const getUpdatedFilters = (filters: TDeviceFilter[], clickedFilterIndex: number) => {
  return filters.map((filter, index) => {
    return clickedFilterIndex === index && filter.selected
      ? filter
      : {
        ...filter,
        selected: clickedFilterIndex === index ? !filter.selected : false,
      };
  });
};

export default AntivirusTable;
