import { SupportedOS, SupportedPlatform } from 'app_constants';
import { AppInstallationState, DeviceStatus, HealthState, RtpState } from 'app_constants/status';
import { AVPolicyInfo, TDevice } from 'types';

export const isAntivirusInstalled = (device?: TDevice): boolean => {
  if (!device || !device.av) return false;

  return device.av.avInstalled;
};

export const hasDetectedAVSignature = (device?: TDevice): boolean => {
  if (!device || !device.av) return false;

  const { detectedAVSignature } = device.av || {};

  return Boolean(detectedAVSignature);
};

export const isOffline = (device?: TDevice): boolean => {
  return device?.status === DeviceStatus.OFFLINE;
};

export const isProDevice = (device?: TDevice): boolean => {
  if (!device) return false;

  const { premium, verified } = device;

  return premium && verified;
};

export const isScanInProgress = (device?: TDevice): boolean => {
  if (!device || !device.av) return false;

  return device.av.avHealthState === HealthState.SCAN_IN_PROGRESS;
};

export const isRtpEnabled = (device?: TDevice): boolean => {
  if (!device || !device.av) return false;

  return device.av.rtpState === RtpState.ENABLED;
};

export const isScanAvailable = (device?: TDevice): boolean => {
  if (!device || !device.av) return false;

  return (
    isProDevice(device) &&
    isAntivirusInstalled(device) &&
    !isOffline(device) &&
    hasDetectedAVSignature(device) &&
    !isScanInProgress(device) &&
    Boolean(device.av.scanAvailable)
  );
};

export const isRtpAvailable = (device?: TDevice): boolean => {
  if (!device || !device.av) return false;

  return (
    isProDevice(device) &&
    isAntivirusInstalled(device) &&
    !isOffline(device) &&
    hasDetectedAVSignature(device) &&
    !isRtpEnabled(device) &&
    Boolean(device.av.rtpAvailable)
  );
};

export const isUpdateDefinitionsAvailable = (device?: TDevice): boolean => {
  if (!device || !device.av) return false;

  return (
    isProDevice(device) &&
    isAntivirusInstalled(device) &&
    !isOffline(device) &&
    hasDetectedAVSignature(device) &&
    Boolean(device.av.updateDefinitionsAvailable)
  );
};

export const isFirewallAvailable = (device?: TDevice): boolean => {
  if (!device || !device.firewall) return false;

  return (
    isProDevice(device) &&
    isAntivirusInstalled(device) &&
    !isOffline(device) &&
    hasDetectedAVSignature(device) &&
    Boolean(device.firewall.firewallAvailable)
  );
};

export const isGoToResolveEndpointProtectionInstalled = (device?: TDevice): boolean => {
  if (!device || !device.av) {
    return false;
  }

  const productNames = ['LogMeIn Antivirus', 'GoTo Resolve Endpoint Protection'];

  return device.av.avInstalled && productNames.includes(device.av.detectedAVName);
};

export const isSupported = (device?: TDevice): boolean => {
  return !!device && isSupportedPlatform(device) && isSupportedOS(device);
};

export const isSupportedPlatform = (device?: TDevice): boolean => {
  return (
    !!device?.platform &&
    new RegExp(Object.values(SupportedPlatform).join('|'), 'i').test(device.platform)
  );
};

export const isSupportedOS = (device?: TDevice): boolean => {
  return (
    !!device?.osName && new RegExp(Object.values(SupportedOS).join('|'), 'i').test(device.osName)
  );
};

export const canInstallApplication = (device?: TDevice): boolean => {
  return (
    (device?.patchmanagement?.appInstallationCapability ?? AppInstallationState.AVAILABLE) ==
    AppInstallationState.AVAILABLE
  );
};

export const isWingetNotInstalled = (device?: TDevice): boolean => {
  return (
    device?.patchmanagement?.appInstallationCapability == AppInstallationState.WINGET_NOT_FOUND
  );
};

export const getPolicyInfo = (
  device?: TDevice,
  policies?: Map<string, AVPolicyInfo>
): { name: string; id: string } | undefined => {
  if (policies === undefined) {
    return undefined;
  }
  if (device === undefined) {
    return undefined;
  }
  const policy = policies.get(device?.id);
  if (policy === undefined) {
    return undefined;
  }

  return { name: policy.name, id: policy.id };
};
