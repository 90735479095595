import { LitElement, html } from 'lit';
import { navigate } from 'synapse-js/events/navigation';
import i18n, { TFunction } from 'services/app/i18n';
import { AVPolicyInfo, TDevice, TAppOptions } from 'types';
import {
  SVG_SCREEN_OUTLINED,
  SVG_TICKET_OUTLINED,
  SVG_PLAY_OUTLINED,
  SVG_REFRESH_FILLED,
} from '@getgo/chameleon-icons';
import { ExternalPath, Path } from 'app_constants/path';
import { ADMIN_CAPABILITY, HELPDESK_CAPABILITY } from 'app_constants/modal';
import { getLocaleDateString } from 'utils/getLocaleDateString';
import { TDeviceFilter } from '../types';
import {
  getPolicyInfo,
  isScanAvailable,
  isScanInProgress,
  isSupported,
} from 'services/device-functionality';
import { hasCapability } from 'services/user';
import FieldFormatters from 'devices-component/utils/field-formatter';
import { sortableStatus } from 'utils/sortableStatus';
import { GTReEppInstallationStatus } from './ContextMenu/ContextMenu';
import { FirewallState, HealthState, RtpState } from 'app_constants/status';
import 'devices-component/devices-selector';
import './FirewallStatus';
import './ProtectionStatus';
import './AVHealth';
import './ContextMenu';
import { getFieldsQuery } from './queries';

const COMPONENT_TAG_NAME = 'av-devices-table';

class DevicesTable extends LitElement {
  t: TFunction;
  theme: string;
  token: string;
  language: string;
  options: string;
  searchText: string;
  filterName: string;
  isHelpdesk: boolean;
  isAdmin: boolean;
  checkLicenses?: () => boolean;
  devicePolicyMap?: Map<string, AVPolicyInfo>;
  showPolicyColumn: boolean;
  private devicesInstallingGtreEpp: Set<string>;

  static get properties() {
    return {
      theme: { type: String },
      token: { type: String },
      language: { type: String },
      options: { type: String },
      searchText: { type: String, attribute: 'search-text' },
      filterName: { type: String, attribute: 'filter-name' },
      checkLicenses: { type: Function, attribute: false },
      devicePolicyMap: { type: Map, attribute: undefined },
      showPolicyColumn: { type: Boolean },
      devicesInstallingGtreEpp: {
        type: Set,
        attribute: 'devicesInstallingGtreEpp',
        state: true,
      },
    };
  }

  constructor() {
    super();
    this.theme = '';
    this.token = '';
    this.language = '';
    this.options = '';
    this.searchText = '';
    this.filterName = '';
    this.isHelpdesk = hasCapability(HELPDESK_CAPABILITY);
    this.isAdmin = hasCapability(ADMIN_CAPABILITY);
    this.t = i18n.getFixedT(null, 'DevicesTable');
    this.renderHoverActions = this.renderHoverActions.bind(this);
    this.onQuickScanClickedHandler = this.onQuickScanClickedHandler.bind(this);
    this.onCreateTicketHandler = this.onCreateTicketHandler.bind(this);
    this.setDevicesInstallingGtreEpp = this.setDevicesInstallingGtreEpp.bind(this);
    this.showPolicyColumn = false;
    this.devicesInstallingGtreEpp = new Set();
  }

  setDevicesInstallingGtreEpp(event: CustomEvent<GTReEppInstallationStatus>) {
    event.detail.inProgress && this.devicesInstallingGtreEpp.add(event.detail.deviceId);
    !event.detail.inProgress && this.devicesInstallingGtreEpp.delete(event.detail.deviceId);

    this.requestUpdate('devicesInstallingGtreEpp');
  }

  onDeviceSelectedHandler(e: CustomEvent) {
    this.onDeviceSelected(e.detail.selected);
  }

  onGroupSelectedHandler(e: CustomEvent) {
    this.onGroupSelected(e.detail.groupIds);
  }

  onDeviceClickedHandler(e: CustomEvent) {
    this.onDeviceClicked(e.detail.row);
  }

  onQuickScanClickedHandler(device: TDevice) {
    this.onQuickScanClicked(device);
  }

  onFullScanClickedHandler(device: TDevice) {
    this.onFullScanClicked(device);
  }

  onCreateTicketHandler(device: TDevice) {
    this.onCreateTicketClicked(device);
  }

  onShowReportHandler(device: TDevice) {
    this.contextMenuHandlers.showLastScanReport(device);
  }

  navigateToDevice(id: string) {
    navigate(`/${id}`, ExternalPath.DEVICE_DETAILS);
  }

  navigateToPolicy(id: string) {
    navigate(`/${id}`, Path.POLICY_DETAILS);
  }

  renderHoverActions(device: TDevice) {
    return html`
      <style>
        .row-controls {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;
        }

        .row-controls .loader-button::part(start) {
          display: flex;
          transform-origin: 4px 9px;
          animation-delay: 0ms;
          animation-direction: normal;
          animation-duration: var(--goto-duration-sluggish);
          animation-fill-mode: forwards;
          animation-iteration-count: infinite;
          animation-play-state: running;
          animation-timing-function: var(--goto-standard-easing);
          animation-name: button-loading;
        }
      </style>
      <div class="row-controls">
        <chameleon-button variant="neutral" @click=${() => this.navigateToDevice(device.id)}>
          <chameleon-svg slot="start">${SVG_SCREEN_OUTLINED}</chameleon-svg>
          ${this.t('manage-device')}
        </chameleon-button>
        ${this.isHelpdesk
          ? html`<chameleon-button
              variant="neutral"
              @click=${() => this.onCreateTicketHandler(device)}
            >
              <chameleon-svg slot="start">${SVG_TICKET_OUTLINED}</chameleon-svg>
              ${this.t('create-ticket')}
            </chameleon-button>`
          : null}
        ${isScanInProgress(device)
          ? html`
              <chameleon-button disabled>
                <chameleon-svg slot="start">${SVG_REFRESH_FILLED}</chameleon-svg>
                ${this.t('in-progress')}
              </chameleon-button>
            `
          : html`
              <chameleon-button
                @click=${() => this.onQuickScanClickedHandler(device)}
                disabled=${!isScanAvailable(device)}
              >
                <chameleon-svg slot="start">${SVG_PLAY_OUTLINED}</chameleon-svg>
                ${this.t('run-quick-scan')}
              </chameleon-button>
            `}
        <av-context-menu
          .device=${device}
          .handlers=${this.contextMenuHandlers}
          .options=${this.options}
          .checkLicenses=${this.checkLicenses}
          @gtre-epp-installation-progress=${this.setDevicesInstallingGtreEpp}
        ></av-context-menu>
      </div>
    `;
  }

  get tableFields() {
    return {
      deviceName: {
        type: 'deviceName',
        columnSelector: false,
        name: this.t('nickname'),
        converter: (value: string) => {
          return html`<span title="${value}">${value}</span>`;
        },
      },
      hostName: {
        type: 'hostName',
        name: this.t('hostname'),
        converter: (value: string) => {
          return html`<span title="${value}">${value}</span>`;
        },
      },
      policies: this.showPolicyColumn
        ? {
            type: 'policies',
            name: this.t('policies'),
            sortable: false,
            converter: (_value: undefined, device: TDevice) => {
              const policyInfo = getPolicyInfo(device, this.devicePolicyMap);

              return policyInfo != undefined
                ? this.isAdmin
                  ? html`<chameleon-link
                      color="var(--type-color-default)"
                      @click=${() => this.navigateToPolicy(policyInfo.id)}
                      >${policyInfo.name}</chameleon-link
                    >`
                  : html`<span title="${policyInfo.name}">${policyInfo.name}</span>`
                : html``;
            },
          }
        : {},
      status: {
        type: 'status',
        name: this.t('status'),
        direction: 'desc',
        sortKeyFn: sortableStatus,
        converter: FieldFormatters.status,
      },
      antivirusHealth: {
        type: 'antivirusHealth',
        name: this.t('antivirus-health'),
        sortKeyFn: (_value: undefined, device: TDevice) => {
          return device.av?.avHealthState || HealthState.UNKNOWN;
        },
        converter: (_value: undefined, device: TDevice) => {
          return isSupported(device)
            ? this.devicesInstallingGtreEpp.has(device.id)
              ? html`<av-health
                  .status=${HealthState.GTRE_EPP_INSTALLATION_IN_PROGRESS}
                ></av-health>`
              : html`<chameleon-link
                  color="var(--type-color-default)"
                  @click="${() => this.onShowReportHandler(device)}"
                >
                  <av-health .status=${device.av?.avHealthState}></av-health>
                </chameleon-link>`
            : html`<av-health .status=${HealthState.UNSUPPORTED_DEVICE}></av-health>`;
        },
      },
      realTimeProtection: {
        type: 'realTimeProtection',
        name: this.t('real-time-protection'),
        sortKeyFn: (_value: undefined, device: TDevice) => {
          return device.av?.rtpState || RtpState.UNKNOWN;
        },
        converter: (_value: undefined, device: TDevice) => {
          return isSupported(device)
            ? html`<av-protection-status .status=${device.av?.rtpState}></av-protection-status>`
            : // prettier-ignore
              html`<av-protection-status .status=${RtpState.UNSUPPORTED_DEVICE}></av-protection-status>`;
        },
      },
      firewall: {
        type: 'firewall',
        name: this.t('firewall'),
        sortKeyFn: (_value: undefined, device: TDevice) => {
          return device.firewall?.firewallState || FirewallState.UNKNOWN;
        },
        converter: (_value: undefined, device: TDevice) => {
          return isSupported(device)
            ? html`<av-firewall-status
                .status=${device.firewall?.firewallState}
              ></av-firewall-status>`
            : html`<av-firewall-status
                .status=${FirewallState.UNSUPPORTEDDEVICE}
              ></av-firewall-status>`;
        },
      },
      antivirusSoftware: {
        type: 'antivirusSoftware',
        name: this.t('antivirus-software'),
        sortKeyFn: (_value: undefined, device: TDevice) => {
          return device.av?.detectedAVName;
        },
        converter: (_value: undefined, device: TDevice) => {
          const detectedAVName = isSupported(device)
            ? device.av?.detectedAVName || ''
            : this.t('unsupported-device');

          return this.devicesInstallingGtreEpp.has(device.id)
            ? html`<span style="white-space: normal;">${this.t('installing-gtre-epp')}</span>`
            : html`<span style="white-space: normal;" title="${detectedAVName}"
                >${detectedAVName}</span
              >`;
        },
      },
      virusDefinitionDatabase: {
        type: 'virusDefinitionDatabase',
        name: this.t('virus-definition-database'),
        sortKeyFn: (_value: undefined, device: TDevice) => {
          return device.av?.definitionNumber;
        },
        converter: (_value: undefined, device: TDevice) => {
          const definitionNumber = device.av?.definitionNumber || '';

          return html`<span style="white-space: normal;" title="${definitionNumber}"
            >${definitionNumber}</span
          >`;
        },
      },
      lastScan: {
        type: 'lastScan',
        name: this.t('last-scan'),
        sortKeyFn: (_value: undefined, device: TDevice) => {
          return new Date(device.av?.lastScanOn ?? 0).getTime();
        },
        converter: (_value: undefined, device: TDevice) => {
          const lastScanOn = device.av?.lastScanOn || '';
          if (!lastScanOn || lastScanOn.startsWith('0001')) {
            return '';
          }

          const lastScanOnFormatted = getLocaleDateString(lastScanOn, { weekday: 'short' });

          return html`<span style="white-space: normal;" title="${lastScanOnFormatted}"
            >${lastScanOnFormatted}</span
          >`;
        },
      },
    };
  }

  render() {
    const { theme, language, token, options, searchText } = this;

    const parsedOptions: TAppOptions = options && JSON.parse(options);

    //TODO Remove query attribute once devices-component can be upgraded to 0.0.2056 or higher
    return html`
      <devices-selector-table
        token=${token}
        theme=${theme}
        search-text=${searchText}
        language=${language}
        .fields=${this.tableFields}
        .options=${{ ...parsedOptions }}
        .filters=${this.filters.find((filter: TDeviceFilter) => filter.selected)?.data || {}}
        .renders=${{
          renderHoverActions: this.renderHoverActions,
        }}
        @device-selected=${this.onDeviceSelectedHandler}
        @group-clicked=${this.onGroupSelectedHandler}
        group-by-device-groups
        query=${getFieldsQuery()}
      ></devices-selector-table>
    `;
  }
}

if (!customElements.get(COMPONENT_TAG_NAME)) {
  customElements.define(COMPONENT_TAG_NAME, DevicesTable);
}

export default DevicesTable;
