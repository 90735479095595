import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { TFunction, useTranslation } from 'react-i18next';
import {
  Alert,
  Button,
  Option,
  Select,
  TextArea,
  TextField,
  Typography,
} from '@getgo/chameleon-web-react-wrapper';
import { useModal, useToastNotification } from 'shared/hooks';
import { helpdeskApi } from 'services/api';
import { CREATE_TICKET_MODAL, HELPDESK_CAPABILITY, MODAL_ACTIONS } from 'app_constants/modal';
import { AlertOutlinedIcon } from '@getgo/chameleon-icons/react';
import { getLocaleDateString } from 'utils/getLocaleDateString';
import { getHealthState } from 'utils/device';
import { hasCapability } from 'services/user';
import { HelpdeskPriorityStatus, TRtpState, rtpMap } from 'app_constants/status';
import { logErrorWithDescription } from 'services/app/telemetry/telemetry';
import { TDevice } from 'types';

const DEFAULT = 'default';

function getSubject(device: TDevice, t: TFunction<'CreateTicketModal'>) {
  const { avHealthState } = device.av || {};
  const HealthState = getHealthState(avHealthState).join(', ');

  return device.av
    ? `${HealthState}`
    : `${t('DevicesTable:no-information-available')} ${t('DevicesTable:on')} ${device.deviceName}`;
}

function getSummary(device: TDevice, t: TFunction<'CreateTicketModal'>) {
  const { avHealthState, detectedAVName, definitionNumber, rtpState, lastScanOn } = device.av || {};

  const noInfo = t('DevicesTable:no-information-available');
  const HealthState = getHealthState(avHealthState).join(', ');

  if (device.av) {
    return [
      `${t('DevicesTable:antivirus-health')}: ${HealthState}`,
      `${t('DevicesTable:antivirus-software')}: ${detectedAVName ?? noInfo}`,
      `${t('DevicesTable:virus-definition-database')}: ${definitionNumber ?? noInfo}`,
      `${t('DevicesTable:real-time-protection')}: ${
        rtpMap[rtpState as TRtpState]?.table ?? noInfo
      }`,
      `${t('DevicesTable:last-scan')}: ${getLocaleDateString(lastScanOn, { weekday: 'short' })}`,
    ].join('\n');
  } else {
    return [
      `${t('DevicesTable:antivirus-health')}: ${t('DevicesTable:no-information-available')}`,
      `${t('DevicesTable:antivirus-software')}: ${t('AntivirusTable:no-av-installed')}`,
    ].join('\n');
  }
}

const Content = () => {
  const { t } = useTranslation(CREATE_TICKET_MODAL);
  const isValid = hasCapability(HELPDESK_CAPABILITY);
  const {
    state: { name, devices, isEnabledRequest },
    close,
    enableSubmit,
    disableSubmit,
    disableRequest,
  } = useModal();

  const [subject, setSubject] = useState(getSubject(devices[0], t));
  const [summary, setSummary] = useState(getSummary(devices[0], t));
  const [selectedServiceId, setSelectedServiceId] = useState(DEFAULT);
  const [selectedCategoryId, setSelectedCategoryId] = useState(DEFAULT);

  const { showSuccess } = useToastNotification();

  const { data: serviceData, isLoading: isServiceLoading } = useQuery(
    [MODAL_ACTIONS[CREATE_TICKET_MODAL]],
    helpdeskApi.getServices,
    {
      enabled: name === CREATE_TICKET_MODAL && isValid,
      onSuccess: (data) => setSelectedServiceId(data.services[0].id.toString() ?? ''),
    }
  );

  const { data: categoryData, isLoading: isCategoryLoading } = useQuery(
    [MODAL_ACTIONS[CREATE_TICKET_MODAL], { id: selectedServiceId }],
    helpdeskApi.getServiceCategories,
    {
      enabled: !!selectedServiceId,
      onSuccess: (data) => setSelectedCategoryId(data.data.categories[0].id),
    }
  );

  const { mutate: sendRequest, isLoading: isSending } = useMutation(helpdeskApi.createTicket, {
    onError: logErrorWithDescription('Error: creating helpdesk ticket'),
    onSuccess: () => {
      showSuccess(t('ticket-created'));
      disableRequest();
      close();
    },
  });

  const isSubmitEnabled = !isServiceLoading && !isCategoryLoading && serviceData && categoryData;
  useEffect(() => {
    if (isSubmitEnabled) {
      enableSubmit();
    } else {
      disableSubmit();
    }
  }, [disableSubmit, enableSubmit, isSubmitEnabled]);

  const priorityId =
    categoryData?.data.service_priorities.find(
      (priority) => priority.priority_level === HelpdeskPriorityStatus.MEDIUM
    )?.id || '';

  useEffect(() => {
    isEnabledRequest &&
      sendRequest({
        serviceId: selectedServiceId,
        categoryId: selectedCategoryId,
        priorityId,
        subject,
        summary,
      });
  }, [
    isEnabledRequest,
    sendRequest,
    selectedCategoryId,
    selectedServiceId,
    priorityId,
    subject,
    summary,
  ]);

  const isInputDisabled = !isValid || isSending;

  return (
    <>
      {isValid || (
        <Typography gutterBottom={true}>
          <Alert variant="danger" icon={<AlertOutlinedIcon />}>
            {t('warning')}
          </Alert>
        </Typography>
      )}

      <Typography variant="body-medium" gutterBottom={true}>
        {t('description')}
      </Typography>
      <Typography variant="body-medium-strong" gutterBottom={true}>
        <TextField
          disabled={isInputDisabled}
          value={subject}
          style={{ width: '100%' }}
          onChange={(e) => setSubject(e.target.value || '')}
        >
          {t('subject')}
        </TextField>
      </Typography>
      <Typography variant="body-medium-strong" gutterBottom={true}>
        <TextArea
          disabled={isInputDisabled}
          value={summary}
          style={{ width: '100%', height: '120px' }}
          onChange={(e) => setSummary(e.target.value || '')}
        >
          {t('summary')}
        </TextArea>
      </Typography>

      <Typography gutterBottom={true}>
        <Select
          label={t('helpdesk-service')}
          disabled={isInputDisabled}
          fullwidth
          inDialog
          value={selectedServiceId}
          onChange={(e) => setSelectedServiceId(e.target.value)}
        >
          {isValid &&
            (isServiceLoading ? (
              <Option value={DEFAULT}>{t('Common:loading')}</Option>
            ) : (
              serviceData?.services.map(({ id, name }) => (
                <Option key={id} value={id}>
                  {name}
                </Option>
              ))
            ))}
        </Select>
      </Typography>

      <Typography gutterBottom={true}>
        <Select
          label={t('category')}
          disabled={isInputDisabled}
          fullwidth={true}
          inDialog
          value={selectedCategoryId}
          onChange={(e) => setSelectedCategoryId(e.target.value)}
        >
          {isValid &&
            (isServiceLoading || isCategoryLoading ? (
              <Option value={DEFAULT}>{t('Common:loading')}</Option>
            ) : (
              categoryData?.data.categories.map(({ id, name }) => (
                <Option key={id} value={id}>
                  {name}
                </Option>
              ))
            ))}
        </Select>
      </Typography>
    </>
  );
};

export const Actions = () => {
  const { t } = useTranslation(CREATE_TICKET_MODAL);
  const {
    state: { isDisabledSubmit },
    close,
    enableRequest,
  } = useModal();

  return (
    <>
      <Button disabled={isDisabledSubmit} onClick={enableRequest}>
        {t('submit-button')}
      </Button>
      <Button variant="neutral" onClick={close}>
        {t('close-button')}
      </Button>
    </>
  );
};

export default Content;
