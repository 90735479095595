import { LitElement, html, css } from 'lit';
import { SVG_MORE_MENU_HORIZONTAL_OUTLINED } from '@getgo/chameleon-icons';
import { TAppOptions, TDevice } from 'types';
import i18n, { TFunction } from 'services/app/i18n';
// import {Integration, Platform} from 'app_constants';
import {
  isScanAvailable,
  isRtpAvailable,
  isUpdateDefinitionsAvailable,
  isFirewallAvailable,
  isGoToResolveEndpointProtectionInstalled,
  isOffline,
  isSupported,
  canInstallApplication,
  isWingetNotInstalled,
} from 'services/device-functionality';
import {
  firewallMap,
  FirewallState,
  rtpMap,
  RtpState,
  TFirewallState,
  TRtpState,
} from 'app_constants/status';
import { GotoAntivirusInstallService } from 'integrations/goto-antivirus';
import loaderIcon from '@getgo/chameleon-icons/dist/rebranded/loader.svg';
import telemetry from '../../../../services/app/telemetry';
import { addLicense } from 'services/api/licenses/licenses';

const COMPONENT_TAG_NAME = 'av-context-menu';

type Action = {
  text: string;
  handler: () => void;
  disabled?: boolean;
  loading?: boolean;
  errorTooltipShown?: boolean;
  errorMessage?: string;
};

type Section = {
  name: string;
  items: Action[];
};

export type GTReEppInstallationStatus = { inProgress: boolean; deviceId: string };

class ContextMenu extends LitElement {
  options: string;
  device?: TDevice;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handlers: any;
  text: string;
  private _active: boolean;
  private _sections: Section[];
  t: TFunction;
  private gotoAntivirusInstallInProgress: boolean;
  private licenseRefreshInProgress: boolean;
  checkLicenses?: () => boolean;

  static get properties() {
    return {
      options: { type: String, attribute: 'options' },
      text: { type: String, attribute: 'text' },
      device: { attribute: 'device' },
      handlers: { attribute: 'handlers' },
      _active: { type: Boolean, attribute: 'active', state: true },
      gotoAntivirusInstallInProgress: {
        type: Boolean,
        attribute: 'gotoAntivirusInstallInProgress',
        state: true,
      },
      licenseRefreshInProgress: {
        type: Boolean,
        attribute: 'licenseRefreshInProgress',
        state: true,
      },
      checkLicenses: { type: Function, attribute: false },
    };
  }

  static get styles() {
    return [
      css`
        @keyframes spinning-icon {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }

        .icon--spinning {
          animation: spinning-icon 1s linear infinite;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.options = '';
    this.text = '';
    this._active = false;
    this.t = i18n.getFixedT(null, 'DevicesTable');
    this.gotoAntivirusInstallInProgress = false;
    this.licenseRefreshInProgress = false;

    this._sections = [];
    this.handleFullScanAction = this.handleFullScanAction.bind(this);
    this.handleDefinitionAction = this.handleDefinitionAction.bind(this);
    this.handleReportAction = this.handleReportAction.bind(this);
    this.handleEnableRTPAction = this.handleEnableRTPAction.bind(this);
    this.handleEnableFirewallAction = this.handleEnableFirewallAction.bind(this);
  }

  async handleInstallAntivirusAction() {
    if (this.checkLicenses?.() && this.device) {
      const device = this.device;
      let statusTimeout: number;
      this.gotoAntivirusInstallInProgress = true;
      this.dispatchInstallationProgress(this.gotoAntivirusInstallInProgress);

      const abort = function (this: ContextMenu) {
        window.clearTimeout(statusTimeout);
        this.gotoAntivirusInstallInProgress = false;
        this.dispatchInstallationProgress(this.gotoAntivirusInstallInProgress);
        this.requestUpdate('gotoAntivirusInstallInProgress');

        return Promise.resolve();
      };

      const service = new GotoAntivirusInstallService();
      try {
        const { job, failedDevices } = await service.install({ devices: [device] });

        if (!job) {
          if (failedDevices.some(({ reason }) => reason === 'licenses-exhausted')) {
            document.body.dispatchEvent(
              new CustomEvent('installation-failed-no-license-left', { bubbles: true })
            );
          } else {
            document.body.dispatchEvent(
              new CustomEvent('installation-failed-unknown-error', { bubbles: true })
            );
          }
          await abort.bind(this)();

          return;
        }
        const openModalAfterTimeout = (): void => {
          if (this.gotoAntivirusInstallInProgress) {
            document.body.dispatchEvent(new CustomEvent('installation-stalled', { bubbles: true }));
          }
        };
        const five_minutes = 5 * 60 * 1000;
        setTimeout(openModalAfterTimeout, five_minutes);

        const getStatus = () =>
          (statusTimeout = window.setTimeout(async () => {
            const status = await service.status(job.id, device.id);
            if (status?.finishedAt) {
              this.gotoAntivirusInstallInProgress = false;
              this.dispatchInstallationProgress(this.gotoAntivirusInstallInProgress);

              telemetry.trackEvent(telemetry.events.INSTALL_ENDPOINT_PROTECTION, {
                jobResult: status.status,
              });
              this.requestUpdate('gotoAntivirusInstallInProgress');
            } else {
              getStatus();
            }
          }, 10000));
        getStatus();
      } catch {
        await abort.bind(this)();
      }
    }
  }

  dispatchInstallationProgress(inProgress: boolean) {
    this.dispatchEvent(
      new CustomEvent<GTReEppInstallationStatus>('gtre-epp-installation-progress', {
        bubbles: true,
        detail: { inProgress, deviceId: this.device!.id },
      })
    );
  }

  handleRefreshLicenseAction() {
    if (!this.device) {
      return;
    }
    this.licenseRefreshInProgress = true;
    const id = this.device.id;
    new Promise((resolve) => {
      addLicense(id).then(resolve);
      window.setTimeout(resolve, 250);
    }).then(() => {
      this.licenseRefreshInProgress = false;
      this.hideContextMenu();
    });
  }

  handleFullScanAction() {
    this.handlers.runFullScan(this.device);
  }

  handleDefinitionAction() {
    this.handlers.updateVirusDefinition(this.device);
  }

  handleReportAction() {
    this.handlers.showLastScanReport(this.device);
  }

  handleEnableRTPAction() {
    this.handlers.enableRTP(this.device);
  }

  handleEnableFirewallAction() {
    this.handlers.enableFirewall(this.device);
  }

  handleDisableFirewallAction() {
    this.handlers.disableFirewall(this.device);
  }

  handleAlertsAction() {
    this.handlers.setupAlerts(this.device);
  }

  showContextMenu() {
    const parsedOptions: TAppOptions = this.options && JSON.parse(this.options);
    const isRtp = isRtpAvailable(this.device);
    const rtpStatus = this.device?.av?.rtpState || RtpState.UNKNOWN;
    const isFirewall = isFirewallAvailable(this.device);
    const firewallState = this.device?.firewall?.firewallState || FirewallState.UNKNOWN;
    this._sections = [];

    this._sections.push(
      {
        name: this.t('scans-context-menu-title'),
        items: [
          {
            text: this.t('option-full-scan'),
            handler: this.handleFullScanAction,
            disabled: !isScanAvailable(this.device),
          },
          {
            text: this.t('option-report'),
            handler: this.handleReportAction,
          },
        ],
      },
      {
        name: this.t('remote-actions-context-menu-title'),
        items: [
          {
            text: this.t('option-update-definition'),
            handler: this.handleDefinitionAction,
            disabled: !isUpdateDefinitionsAvailable(this.device),
          },
          isRtp && {
            text: rtpMap[rtpStatus as Exclude<TRtpState, typeof RtpState.UNKNOWN>].menu,
            handler: this.handleEnableRTPAction,
          },
          isFirewall && {
            text: firewallMap[
              firewallState as Exclude<
                TFirewallState,
                typeof FirewallState.UNKNOWN | typeof FirewallState.NOTSUPPORTED
              >
            ].menu,
            handler:
              firewallState === FirewallState.ENABLED
                ? this.handleDisableFirewallAction
                : this.handleEnableFirewallAction,
          },
        ].filter((item) => item) as Action[],
      }
      // {
      //   name: this.t('alerts-context-menu-title'),
      //   items: [
      //     {
      //       text: this.t('option-set-up-alerts'),
      //       handler: () => {},
      //       disabled: true,
      //     },
      //   ],
      // },
    );

    // Disable this for now
    /* this.device?.platform === Platform.WIN &&
      this._sections.push({
        name: this.t('alerts-context-menu-title'),
        items: [
          {
            text: this.t('option-set-up-alerts'),
            handler: this.handleAlertsAction,
          },
        ],
      }); */

    if (parsedOptions.features?.AntivirusInstallMenu) {
      this._sections.push({
        name: this.t('antivirus-installer-context-menu-title'),
        items: [
          this.device && isGoToResolveEndpointProtectionInstalled(this.device)
            ? {
                text: this.t('option-refresh-license'),
                handler: this.handleRefreshLicenseAction,
                disabled: this.licenseRefreshInProgress,
                loading: this.licenseRefreshInProgress,
              }
            : {
                text: this.t('option-install-antivirus'),
                handler: this.handleInstallAntivirusAction,
                disabled:
                  isOffline(this.device) ||
                  !isSupported(this.device) ||
                  !canInstallApplication(this.device) ||
                  this.gotoAntivirusInstallInProgress,
                loading: this.gotoAntivirusInstallInProgress,
                errorTooltipShown: !canInstallApplication(this.device),
                errorMessage: isWingetNotInstalled(this.device)
                  ? this.t('winget-not-installed-tooltip')
                  : this.t('winget-not-accessible-tooltip'),
              } /*,
          {
            text: this.t('option-configure-antivirus'),
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            handler: () => {},
            disabled: true,
          }*/,
        ],
      });
    }

    this._active = true;
  }

  hideContextMenu() {
    this._active = false;
  }

  renderSection(name: string, items: Action[]) {
    return html`
      <chameleon-menu-section>${name}</chameleon-menu-section>
      ${items.map(
        ({ handler, disabled, text, loading, errorMessage, errorTooltipShown }: Action) => html`
          <chameleon-menu-item
            size="medium"
            @click=${disabled ? null : handler}
            disabled=${Boolean(disabled)}
          >
            ${loading
              ? html` <synapse-icon
                  class="icon icon--spinning"
                  style="--goto-icon-color: var(--goto-icon-color-brand-default)"
                  .svg="${loaderIcon}"
                  slot="start"
                ></synapse-icon>`
              : ''}${!errorTooltipShown ? text : ''}
            ${errorTooltipShown
              ? html`<chameleon-tooltip>
                  ${text}
                  <span slot="tooltip">${errorMessage}</span>
                </chameleon-tooltip>`
              : ''}
          </chameleon-menu-item>
        `
      )}
    `;
  }

  renderActions() {
    return html`
      <style>
        .actions-menu {
          position: absolute;
          width: 240px;
          right: 0;
          top: 100%;
          box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12),
            0px 2px 4px rgba(0, 0, 0, 0.2);
          border: 1px solid #f7f7f7;
          border-radius: 8px;
        }
      </style>
      <chameleon-menu class="actions-menu">
        ${this._sections.map(({ name, items }, index) => {
          return html`
            ${this.renderSection(name, items)}
            ${index !== this._sections.length - 1
              ? html` <chameleon-menu-separator></chameleon-menu-separator>`
              : ''}
          `;
        })}
      </chameleon-menu>
    `;
  }

  render() {
    return html`
      <style>
        .actions-container {
          display: inline-flex;
          position: relative;
          z-index: 10;
        }
      </style>
      <div
        class="actions-container"
        @mouseenter=${this.showContextMenu}
        @mouseleave=${this.hideContextMenu}
      >
        <chameleon-icon-button class="actions-button" variant="secondary">
          <chameleon-svg style="--goto-icon-size: 24px;">
            ${SVG_MORE_MENU_HORIZONTAL_OUTLINED}
          </chameleon-svg>
        </chameleon-icon-button>
        ${this._active ? this.renderActions() : null}
      </div>
    `;
  }
}

if (!customElements.get(COMPONENT_TAG_NAME)) {
  customElements.define(COMPONENT_TAG_NAME, ContextMenu);
}

export default ContextMenu;
